"use strict";
exports.__esModule = true;
exports.OverlayBlur = exports.OverlayTheme = exports.OverlayType = exports.StepType = exports.FlowWrapper = exports.OpeningAnimation = exports.LinkType = exports.PublishStatus = void 0;
var PublishStatus;
(function (PublishStatus) {
    PublishStatus[PublishStatus["draft"] = 1] = "draft";
    PublishStatus[PublishStatus["published"] = 2] = "published";
    PublishStatus[PublishStatus["archived"] = 3] = "archived";
    PublishStatus[PublishStatus["deleted"] = 4] = "deleted";
})(PublishStatus = exports.PublishStatus || (exports.PublishStatus = {}));
var LinkType;
(function (LinkType) {
    LinkType[LinkType["button"] = 1] = "button";
    LinkType[LinkType["link"] = 2] = "link";
})(LinkType = exports.LinkType || (exports.LinkType = {}));
var OpeningAnimation;
(function (OpeningAnimation) {
    OpeningAnimation["Fade and Grow"] = "Fade and Grow";
})(OpeningAnimation = exports.OpeningAnimation || (exports.OpeningAnimation = {}));
var FlowWrapper;
(function (FlowWrapper) {
    FlowWrapper[FlowWrapper["none"] = 1] = "none";
    FlowWrapper[FlowWrapper["browserLight"] = 2] = "browserLight";
    FlowWrapper[FlowWrapper["browserDark"] = 3] = "browserDark";
})(FlowWrapper = exports.FlowWrapper || (exports.FlowWrapper = {}));
var StepType;
(function (StepType) {
    StepType[StepType["Image"] = 1] = "Image";
    StepType[StepType["Video"] = 2] = "Video";
    StepType[StepType["Overlay"] = 3] = "Overlay";
})(StepType = exports.StepType || (exports.StepType = {}));
var OverlayType;
(function (OverlayType) {
    OverlayType[OverlayType["Start"] = 0] = "Start";
    OverlayType[OverlayType["Chapter"] = 1] = "Chapter";
    OverlayType[OverlayType["Final"] = 2] = "Final";
})(OverlayType = exports.OverlayType || (exports.OverlayType = {}));
var OverlayTheme;
(function (OverlayTheme) {
    OverlayTheme["Dark"] = "dark";
    OverlayTheme["Light"] = "light";
})(OverlayTheme = exports.OverlayTheme || (exports.OverlayTheme = {}));
var OverlayBlur;
(function (OverlayBlur) {
    OverlayBlur["Medium"] = "medium";
    OverlayBlur["Small"] = "small";
    OverlayBlur["None"] = "none";
})(OverlayBlur = exports.OverlayBlur || (exports.OverlayBlur = {}));
